import { decrement, increment, showTurnstile, store } from "@/Reducers/store";
import axios from "axios";

/**
 * Set defaults on axios to power things like disabling buttons
 */

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        // Tell our global state that a request has started
        console.log(config);
        if (config.headers.get("X-Inertia-Prefetch") !== "true") {
            store.dispatch(increment());
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // Tell our global state that a request has ended
        if (!response.headers["x-inertia-prefetch"]) {
            store.dispatch(decrement());
        }
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // Tell our global state that a request has ended
        store.dispatch(decrement());

        if (error?.response?.headers?.["cf-mitigated"]) {
            // Cloudflare WAF has intercepted the request, trigger the CF Turnstile
            store.dispatch(showTurnstile());
        }

        return Promise.reject(error);
    },
);

export default axios;
